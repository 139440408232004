

























































































































import Vue from "vue";
import { ContactClient, ContactUs, ContactUsReason, IContactUs } from "@/api/DoceoApi";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import InfoDialog from "@/components/userManagement/InfoDialog.vue";

export default Vue.extend({
  name: "ContactUs",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  mixins: [validationMixin],
  components: { DoceoIcon, InfoDialog },
  data: () => ({
    DOCEO_ICONS,

    firstName: "",
    lastName: "",
    email: "",
    message: "",
    reason: ContactUsReason.BusinessInquiry as ContactUsReason,
    contactReasons: [ContactUsReason.BusinessInquiry, ContactUsReason.Investor, ContactUsReason.Press] as ContactUsReason[],

    isInfoDialogOpen: false,
    infoTitle: "Contact us",
    infoMessage: "",
    infoButton: "Okay",

    captchaSiteKey: null as string | null,
  }),
  validations: {
    firstName: { required },
    lastName: { required },
    email: { required, email: (val) => (email as any)(val.toLowerCase()) },
    message: { required },
    reason: { required },
  },
  created() {
    this.captchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY ?? null;
  },
  mounted() {
    // add captcha script to this component
    const captchaScript = document.createElement("script");
    captchaScript.setAttribute("src", "https://www.google.com/recaptcha/api.js?render=" + this.captchaSiteKey);
    captchaScript.setAttribute("async", "");
    document.getElementById("contactCaptchaScript")?.appendChild(captchaScript);
  },
  computed: {
    firstNameErrors() {
      const errors = [] as string[];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required && errors.push("First name is required.");
      return errors;
    },
    lastNameErrors() {
      const errors = [] as string[];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required && errors.push("Last name is required.");
      return errors;
    },
    messageErrors() {
      const errors = [] as string[];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required && errors.push("Message is required.");
      return errors;
    },
    emailErrors() {
      const errors = [] as string[];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid Email");
      !this.$v.email.required && errors.push("Email is required.");
      return errors;
    },
    reasonErrors() {
      const errors = [] as string[];
      if (!this.$v.reason.$dirty) return errors;
      !this.$v.reason.required && errors.push("Reason is required.");
      return errors;
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const grecaptcha = (window as any).grecaptcha;
      const captchaToken = await new Promise<string>((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(this.captchaSiteKey, { action: "contact" }).then((token: string) => {
            resolve(token);
          });
        });
      });

      let contactClient = new ContactClient();
      let contactUs: IContactUs = {
        name: `${this.lastName}, ${this.firstName}`,
        email: this.email,
        message: this.message,
        reason: this.reason,
        reCaptchaToken: captchaToken,
      };

      try {
        await contactClient.addContact(new ContactUs(contactUs));
        this.infoMessage = "Thank you for your interest in the SophiaMed AI project! We will respond back as soon as possible";
        this.isInfoDialogOpen = true;
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.message = "";
        this.$v.$reset();
      } catch {
        this.infoMessage = "Error submitting request";
        this.isInfoDialogOpen = true;
      }
    },
  },
});
