





















import Vue from "vue";

import DoceoAppBar from "@/components/DoceoAppBar.vue";
import MarketingHeader from "./components/marketing/MarketingHeader.vue";
import MarketingFooter from "./components/marketing/MarketingFooter.vue";
import { useErrorStore } from "@/store/errorStore";
import { useProfileStore } from "@/store/profileStore";
import { mapState, mapActions } from "pinia";
import { mapGetters, mapActions as mapActionsVuex } from "vuex";

export default Vue.extend({
  name: "App",
  components: { DoceoAppBar, MarketingHeader, MarketingFooter },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "SophiaMed AI | Medical Information Discovery and Curation Platform",
    htmlAttrs: {
      lang: "en",
    },
  },
  async created() {
    // If the user is logged in but the user isn't set then make a request for the user details (Use case: Page refresh)
    if (this.isLoggedIn && !this.isUserSet) {
      try {
        await this.checkToken();
        await this.getUserAsync();
        await this.getProfileAsync();
        if (this.hasDoctorRole || this.hasAdminRole) {
          await this.getPendingInvites();
        }
      } catch {
        this.$router.push({ name: "Login" });
        this.logout();
      }
    }
    this.loaded = true;
  },
  data: () => ({
    snackbar: true,
    messageSnackbar: true,
    loaded: false,
  }),
  methods: {
    ...mapActionsVuex(["getUserAsync", "getPendingInvites", "checkToken", "logout"]),
    ...mapActions(useProfileStore, ["getProfileAsync"]),
  },
  computed: {
    ...mapGetters(["isUserSet", "userId", "isLoggedIn", "hasDoctorRole", "hasAdminRole"]),
    ...mapState(useErrorStore, ["error", "message"]),
    ...mapState(useProfileStore, ["profile"]),
    isMarketing() {
      return this.$route.meta?.isMarketing ?? false;
    },
  },
});
