
































































































































































































import { ActiveNav } from "@/router";
import Vue from "vue";
import LoginDialog from "@/components/userManagement/LoginDialog.vue";
import RegisterDialog from "@/components/userManagement/RegisterDialog.vue";
import ForgotPasswordDialog from "@/components/userManagement/ForgotPasswordDialog.vue";
import ResetForgotPasswordDialog from "@/components/userManagement/ResetForgotPasswordDialog.vue";
import VerifyEmailDialog from "@/components/userManagement/VerifyEmailDialog.vue";

enum Dialog {
  None,
  Login,
  Register,
  ForgotPassword,
  ResetForgotPassword,
  VerifyEmail,
}

export default Vue.extend({
  name: "MarketingHeader",
  components: {
    LoginDialog,
    RegisterDialog,
    ForgotPasswordDialog,
    ResetForgotPasswordDialog,
    VerifyEmailDialog,
  },
  data: () => ({
    drawerOpen: false,
    openDialog: Dialog.None,
    Dialog,
  }),
  created() {
    this.checkDialogParam();
  },
  methods: {
    checkDialogParam() {
      if (this.$route.name == "Home") {
        switch (this.$route.params?.dialog) {
          case "Login":
            this.openDialog = Dialog.Login;
            break;
          case "Register":
            this.openDialog = Dialog.Register;
            break;
          case "ForgotPassword":
            this.openDialog = Dialog.ForgotPassword;
            break;
          case "ResetPassword":
            this.openDialog = Dialog.ResetForgotPassword;
            break;
          case "VerifyEmail":
            this.openDialog = Dialog.VerifyEmail;
            break;
        }
      }
    },
    goToHome() {
      this.$router.push({ name: "Home" }).catch(() => {});
    },
    closeDialog() {
      this.openDialog = Dialog.None;
      if (this.$route.params.dialog) {
        this.$router.push({ name: this.$route.name! });
      }
    },
  },
  computed: {
    onHomePage() {
      return this.$route.meta?.activeNav === ActiveNav.MarketingHome;
    },
    onAboutUsPage() {
      return this.$route.meta?.activeNav === ActiveNav.MarketingAboutUs;
    },
    onContactPage() {
      return this.$route.meta?.activeNav === ActiveNav.MarketingContact;
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    $route() {
      this.checkDialogParam();
    },
  },
});
