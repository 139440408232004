





















































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters, mapActions as mapActionsVuex } from "vuex";
import { useProfileStore } from "@/store/profileStore";
import { useConversationStore } from "@/store/conversationStore";
import { useFolioStore } from "@/store/folioStore";
import { mapState, mapActions } from "pinia";
import {
  FolioClient,
  FolioInvitationStatus,
  IUpdateFolioInvitationModel,
  PendingFolioInvite,
  UpdateFolioInvitationModel,
} from "@/api/DoceoApi";
import { ChatroomModel } from "@/api/signalRModels/ChatRoomModel";
import DOCEO_ICONS from "@/constants/icons";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DoceoImage from "@/components/DoceoImage.vue";
import SearchType from "@/constants/searchType";
import Roles from "@/constants/roles";
import { ActiveNav } from "@/router";

/**
 * This is the app bar of the app. It contains everything for the header of the app
 */
export default Vue.extend({
  name: "AppBar",
  components: { DoceoIcon, DoceoImage },
  data: () => ({
    drawer: false,
    searchTerm: "",
    mobileSearch: false,
    doctorSearchFilterDialog: false,
    doctorSearchFilters: {
      acceptingNewPatients: false,
    },
    DOCEO_ICONS,
  }),
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    ...mapActionsVuex(["logout", "removeInvite"]),
    ...mapActions(useFolioStore, ["fetchFolios"]),
    ...mapActions(useConversationStore, [
      "acceptChatroomInvite",
      "declineChatroomInvite",
    ]),
    acceptChatroomInviteAndGo(chatroom: ChatroomModel) {
      this.acceptChatroomInvite(chatroom).then(() => {
        this.$router.push({
          name: "Discussions",
          query: { chatroomId: chatroom.id },
        });
      });
    },
    declineInvite(invite: PendingFolioInvite) {
      this.updateInvite(invite, FolioInvitationStatus.Declined);
    },
    async acceptInvite(invite: PendingFolioInvite) {
      await this.updateInvite(invite, FolioInvitationStatus.Accepted);

      // If the user is on the folio page, refresh the list of folios to include the one they just accepted
      if (this.$route.name == "Folio") {
        this.fetchFolios();
      }
    },
    async updateInvite(
      invite: PendingFolioInvite,
      status: FolioInvitationStatus
    ) {
      this.removeInvite(invite.id);

      let folioClient = new FolioClient();
      let updateFolioInvitation: IUpdateFolioInvitationModel = {
        inviteId: invite.id,
        userId: this.userId,
        folioInvitationStatus: status,
      };
      await folioClient.updatePendingInvites(
        invite.folioId,
        new UpdateFolioInvitationModel(updateFolioInvitation)
      );
    },
    /**
     * Close the drawer if the window is resized to a screen size of "small" or larger
     */
    myEventHandler() {
      if (!this.isSmallScreen) {
        this.drawer = false;
      }
    },
    /**
     * Navigates to the search page with the user's inputted search term and clears the search input box.
     */
    onSearch() {
      if (this.searchTerm !== "") {
        switch (this.$route.meta?.searchType) {
          case SearchType.Content:
            this.$router
              .push({ name: "Search", query: { searchTerm: this.searchTerm } })
              .catch(() => {});
            break;
        }
        this.mobileSearch = false;
      }
    },

    /**
     * Perform a logout and redirect to the home page
     */
    async performLogout() {
      await this.logout();
      this.$router.push({ name: "Home" });
    },
    /**
     * Navigate to the discussions page
     */
    goToDiscussions() {
      this.$router.push({ name: "Discussions" }).catch(() => {});
    },
    /**
     * Navigate to the discover page
     */
    goToDiscover() {
      this.$router.push({ name: "Discover" }).catch(() => {});
    },
    /**
     * Navigate to the folio page
     */
    goToFolio() {
      this.$router.push({ name: "Folio" }).catch(() => {});
    },
    /**
     * Navigate to the doctor page
     */
    goToDoctorSearch() {
      this.$router.push({ name: "DoctorSearch" }).catch(() => {});
    },
    /**
     * Navigate to the "my content" page
     */
    goToMyContent() {
      this.$router.push({ name: "MyContent" }).catch(() => {});
    },
    /**
     * Navigate to the profile page
     */
    goToProfile() {
      this.$router.push({ name: "Profile" }).catch(() => {});
    },
    /**
     * Navigate to the sponsor account management page
     */
    goToSponsorAccountManagement() {
      this.$router
        .push({ name: "AccountManagement", params: { accountType: "sponsor" } })
        .catch(() => {});
    },
    /**
     * Navigate to the voice-of account management page
     */
    goToVoiceOfAccountManagement() {
      this.$router
        .push({ name: "AccountManagement", params: { accountType: "voiceof" } })
        .catch(() => {});
    },
  },
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "firstName",
      "pendingFolioInvites",
      "userId",
      "roles",
    ]),
    ...mapState(useProfileStore, ["profilePictureAttachmentId"]),
    ...mapState(useConversationStore, [
      "unreadMessageCount",
      "pendingChatroomInvites",
    ]),
    /**
     * Determine whether the user is currently viewing on a "extra small" screen (Phone).
     */
    isPhoneScreen(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": // < 600px
          return true;
        case "sm": // 600px > < 960px
          return false;
        case "md": // 960px > < 1264px
          return false;
        case "lg": // 1264px > < 1904px
          return false;
        case "xl": //	> 1904px
          return false;
        default:
          return false;
      }
    },
    /**
     * Determine whether the user is currently viewing on a "small" screen.
     * Currently we define small screens to be of size "xs" or "sm"
     */
    isSmallScreen(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": // < 600px
          return true;
        case "sm": // 600px > < 960px
          return true;
        case "md": // 960px > < 1264px
          return false;
        case "lg": // 1264px > < 1904px
          return false;
        case "xl": //	> 1904px
          return false;
        default:
          return false;
      }
    },
    /** The total number of notifications that are pending. */
    notificationsCount(): number {
      return (
        this.pendingFolioInvites.length + this.pendingChatroomInvites.length
      );
    },

    showSearch(): boolean {
      return this.$route.meta?.searchType != SearchType.None;
    },
    showSearchFilter(): boolean {
      return false;
    },

    onDiscoverPage(): boolean {
      return this.$route.meta?.activeNav === ActiveNav.Discover;
    },
    onFolioPage(): boolean {
      return this.$route.meta?.activeNav === ActiveNav.Folio;
    },
    onDoctorSearchPage(): boolean {
      return this.$route.meta?.activeNav === ActiveNav.DoctorSearch;
    },
    onMyContentPage(): boolean {
      return this.$route.meta?.activeNav === ActiveNav.MyContent;
    },

    hasFolioAccess(): boolean {
      const allowedRoles = [Roles.Doctor, Roles.Admin];
      return this.roles.some((role) => allowedRoles.includes(role));
    },
    hasDoctorSearchAccess(): boolean {
      const allowedRoles = [Roles.Doctor, Roles.Admin];
      return this.roles.some((role) => allowedRoles.includes(role));
    },
    hasDiscussionAccess(): boolean {
      return this.roles.some((role) => role === Roles.Doctor);
    },
    hasAccountManagementAccess(): boolean {
      return this.roles.some((role) => role === Roles.Admin);
    },
  },
  watch: {
    $route(to, from) {
      // If there is a search term in the query, update the search term
      if (to.query.searchTerm) {
        this.searchTerm = to.query.searchTerm;
      } else {
        this.searchTerm = "";
      }

      // clear search bar when going to different type of search
      if (to.meta?.searchType != from.meta?.searchType) {
        this.searchTerm = "";
      }
    },
  },
});
