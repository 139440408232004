
























































































































































































































import Vue from "vue";
import DoceoIcon from "../DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "MarketingFooter",
  components: { DoceoIcon },
  data: () => ({
    DOCEO_ICONS,
  }),
  methods: {},
  computed: {},
  watch: {},
});
